import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 34 33"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M16.705 32.03v-4.95C9.816 26.724 4.33 21.004 4.33 14.033 4.33 6.827 10.188.969 17.393.969s13.062 5.858 13.062 13.063c0 6.806-4.73 13.654-11.784 17.05zm.688-28.31C11.7 3.72 7.08 8.34 7.08 14.031s4.62 10.312 10.313 10.312h2.062v3.163c5.005-3.163 8.25-8.36 8.25-13.475 0-5.693-4.62-10.313-10.312-10.313m-1.375 15.812h2.75v2.75h-2.75zm2.75-2.063h-2.75c0-4.468 4.125-4.125 4.125-6.875a2.76 2.76 0 0 0-2.75-2.75 2.76 2.76 0 0 0-2.75 2.75h-2.75c0-3.038 2.46-5.5 5.5-5.5 3.038 0 5.5 2.462 5.5 5.5 0 3.438-4.125 3.782-4.125 6.875"
    }, null, -1)
  ])))
}
export default { render: render }