import IconChevronRight from '@/assets/icons/icon-chevron-right.svg?component';
import IconContactSupport from '@/assets/icons/icon-contact-support.svg?component';
import IconLocation from '@/assets/icons/icon-location.svg?component';
import IconPayment from '@/assets/icons/icon-payment.svg?component';
import type { LocaleObject } from '@nuxtjs/i18n';
import type { Customer, Offer } from '@ruokaboksi/api-client';
import type { DefineComponent } from 'vue';

export interface MenuItem {
  name: string;
  external?: boolean;
  label: string | VNode;
  icon: DefineComponent;
  notification?: boolean;
}

export const getAccountMenuContent = (
  t: (key: string) => string,
  localeProperties: ComputedRef<LocaleObject>,
  currentCustomer?: Customer,
  offer?: Offer | null
): {
  menuItems: MenuItem[];
  additionalMenuItems: MenuItem[];
} => {
  const hasNotActivatedLoyalty = currentCustomer?.inLoyaltyProgram === false;

  return {
    menuItems: [
      {
        name: 'more-contact-details',
        label: t('my_account.links.contact_details'),
        icon: IconLocation,
      },
      {
        name: 'more-payment-methods',
        label: t('my_account.links.payment_methods'),
        icon: IconPayment,
      },
      {
        name: 'more-support',
        label: t('my_account.links.support'),
        icon: IconContactSupport,
      },
    ],
    additionalMenuItems: [
      {
        name: 'more-account',
        label: t('my_account.links.account'),
        icon: IconChevronRight,
      },
      {
        name: 'more-offers',
        label: t('my_account.links.offers'),
        icon: IconChevronRight,
        notification:
          (localeProperties.value.loyaltyProgramName &&
            hasNotActivatedLoyalty) ||
          offer?.content,
      },
      {
        name: 'more-deliveries',
        label: t('my_account.links.deliveries'),
        icon: IconChevronRight,
      },
      {
        name: 'more-notifications',
        label: t('my_account.links.notifications'),
        icon: IconChevronRight,
      },
    ],
  };
};
